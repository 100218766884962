import React from "react";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import {
  Box,
  Card,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
// import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { imageURLCombiner } from "src/utils/imageUrlCombiner";
import VideoThumbnail from "./VideoThumbnail";
// import { staticImages } from "src/assets/images";
// import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
// import { Link, useNavigate } from "react-router-dom";

// const headCells = [
//   {
//     id: "id",
//     numeric: true,
//     disablePadding: true,
//     label: "ID",
//   },
// ];

const Results = ({ resultData, pagination, actions }) => {
  // const navigate = useNavigate();
  // const [orderBy, setOrderBy] = useState("");
  // const [order, setOrder] = useState(true);

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  //**** change number of vendors gotten in a page ***
  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };
  // const createSortHandler = (property) => (event) => {
  //   setOrderBy(property);
  //   actions.handleSortBooks({ [property]: order ? 1 : -1 });
  //   setOrder((prev) => !prev);
  // };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchBooks}
          placeholder={"Search by title, decription..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData.length === 0 ? (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {"We couldn't find any books matching your search criteria"}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id && order !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))} */}
                  <TableCell>{"ID"}</TableCell>
                  <TableCell align="center">{"Question Title"}</TableCell>
                  <TableCell align="center">{"Question Descriprion"}</TableCell>
                  <TableCell align="center">{"answer"}</TableCell>
                  <TableCell>{"Photo"}</TableCell>
                  <TableCell>{"Video"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((data, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant="h5">{data?.id || ""}</Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.questionTitle}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5" textAlign="center">
                          {data?.questionDescription || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.answer || ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <StyledImagesWrapper>
                          {data?.medias
                            ?.filter((media) => media.type === "IMAGE")
                            ?.map((media) => {
                              return (
                                <StyledImage
                                  key={media?.path}
                                  alt={`service-${data?.name}-photo`}
                                  src={imageURLCombiner(media?.path)}
                                  onClick={() =>
                                    window.open(media?.path, "_blank")
                                  }
                                />
                              );
                            })}
                        </StyledImagesWrapper>
                      </TableCell>

                      <TableCell>
                        <StyledImagesWrapper>
                          {data?.medias
                            ?.filter((media) => media.type === "VIDEO")
                            .filter((_, i) => i === 0)
                            ?.map((media) => {
                              return (
                                <VideoThumbnail
                                  key={media?.id}
                                  width={100}
                                  height={100}
                                  snapshotAt={40}
                                  videoUrl={media?.path}
                                />
                              );
                            })}
                        </StyledImagesWrapper>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;

const StyledImagesWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledImage = styled("img")`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`;
