import { AxiosInstance } from "../index";

export const adminService = {
  //**** users and admins *****
  getAdminList: (param) =>
    AxiosInstance.get("admin/users/admins", {
      params: fixPageNumber(param),
    }),
  getCustomerList: (param) =>
    AxiosInstance.get("admin/users", {
      params: fixPageNumber(param),
    }),
  createAdmin: (data) => AxiosInstance.post("admin/users/invite-admin", data),
  forgetPasswordForAdmin: (id) =>
    AxiosInstance.post(`admin/users/forgot-password/${id}`),
  getUserInfo: () => AxiosInstance.get("users/me"),
  forgotPassword(body) {
    return AxiosInstance.post("admin/users/forgot-password", body);
  },
  updateUser: (id, data) => AxiosInstance.patch(`admin/users/${id}`, data),
  updatePro: (data) => AxiosInstance.put("admin/users/agents", data),
  getUserDetail: (id) => AxiosInstance.get(`admin/users/${id}`),
  getUserAddress: (id, param) =>
    AxiosInstance.get(`admin/address/${id}`, {
      params: fixPageNumber(param),
    }),
  getProAreas: (id) => AxiosInstance.get(`admin/area/areas/${id}`),
  updateProAreas: (id, data) =>
    AxiosInstance.put(`admin/agents/update-areas/${id}`, data),
  //*** static-pages */
  getStaticPages(data) {
    return AxiosInstance.get("admin/static-pages", {
      params: fixPageNumber(data),
    });
  },
  getStaticPage: (type, language) =>
    AxiosInstance.get(`static-pages/${type}/${language}`),
  editStaticPage: (id, data) =>
    AxiosInstance.patch(`admin/static-pages/${id}`, data),

  //***category */
  getCategories: (param) =>
    AxiosInstance.get("admin/categories", {
      params: fixPageNumber(param),
    }),
  getCategoryDetail: (id) => AxiosInstance.get(`admin/categories/${id}`),
  createCategory: (data) => AxiosInstance.post("admin/categories", data),
  updateCategory: (id, data) =>
    AxiosInstance.patch(`admin/categories/${id}`, data),
  setUnitsForCategory: (id, data) =>
    AxiosInstance.put(
      `admin/unit-of-measurement/update-category-unit-of-measurements/${id}`,
      data
    ),
  revokeCategory: (id) => AxiosInstance.delete(`admin/categories/${id}`),
  reorderCategory: (id, data) =>
    AxiosInstance.patch(`admin/categories/${id}/reorder`, data),
  getRelatedServices: (param) =>
    AxiosInstance.get("/admin/services/", {
      params: fixPageNumber(param),
    }),

  //*** services */
  getServices: (param) =>
    AxiosInstance.get("admin/services", {
      params: fixPageNumber(param),
    }),
  createServices: (data) => AxiosInstance.post("admin/services", data),
  getServiceDetail: (id) => AxiosInstance.get(`admin/services/${id}`),
  updateService: (id, data) =>
    AxiosInstance.patch(`admin/services/${id}`, data),
  revokeService: (id) => AxiosInstance.delete(`admin/services/${id}`),
  reorderService: (id, data) =>
    AxiosInstance.patch(`admin/services/${id}/reorder`, data),
  getCategoriesWithServices: (id) =>
    AxiosInstance.get(`admin/categories/with-services/${id}`),
  updateProServices: (id, data) =>
    AxiosInstance.put(`admin/agents/update-services/${id}`, data),
  getProPaymentsWithId: (param, id) =>
    AxiosInstance.get(`admin/payment/pro/${id}`, {
      params: fixPageNumber(param),
    }),
  //**media */
  uploadMedia: (data) => AxiosInstance.post("admin/media/upload", data),
  deleteMedia: (mediaId) => AxiosInstance.delete(`/admin/media/${mediaId}`),
  //** questionnaires */
  getQuestionnaireList: (param) =>
    AxiosInstance.get("admin/questionnaires", {
      params: fixPageNumber(param),
    }),
  getQuestionnaireDetail: (id) =>
    AxiosInstance.get(`admin/questionnaires/${id}`),
  createQuestionnaire: (data) =>
    AxiosInstance.post("admin/questionnaires", data),
  updateQuestionnaire: (id, data) =>
    AxiosInstance.patch(`admin/questionnaires/${id}`, data),
  toggleQuestionnaireActivation: (id, data) =>
    AxiosInstance.patch(`admin/questionnaires/${id}`, data),
  attachQuestionToQuestinnaire: (data) =>
    AxiosInstance.post("admin/questionnaires/attach-question", data),

  //** questions */
  getQuestionList: (param) =>
    AxiosInstance.get("admin/questions", {
      params: fixPageNumber(param),
    }),
  getQuestionnairQuestions: (id, param) =>
    AxiosInstance.get(`admin/questionnaires/${id}/questions`, {
      params: fixPageNumber(param),
    }),
  createQuestions: (data) => AxiosInstance.post("admin/questions", data),
  updateQuestions: (id, data) =>
    AxiosInstance.patch(`admin/questions/${id}`, data),
  activationQuestion: (questionnairId, qustionId, data) =>
    AxiosInstance.patch(
      `admin/questionnaires/${questionnairId}/${qustionId}`,
      data
    ),
  revokeQuestion: (id) => AxiosInstance.delete(`admin/questions/${id}`),
  reorderQuestions: (id, data) =>
    AxiosInstance.patch(`admin/questions/${id}/reorder`, data),
  //** options */
  getOptionList: (param) =>
    AxiosInstance.get("admin/option", {
      params: fixPageNumber(param),
    }),
  getQuestionOptions: (id, param) =>
    AxiosInstance.get(`admin/questions/${id}/options`, {
      params: fixPageNumber(param),
    }),
  createOptions: (data) => AxiosInstance.post("admin/option", data),
  updateOptions: (id, data) => AxiosInstance.patch(`admin/option/${id}`, data),
  revokeOptions: (id) => AxiosInstance.delete(`admin/option/${id}`),
  reorderOptions: (id, data) =>
    AxiosInstance.patch(`admin/option/${id}/reorder`, data),
  //** booking */
  getBookRequestList: (param) =>
    AxiosInstance.get("admin/books", {
      params: fixPageNumber(param),
    }),
  getBookDetail: (id) => AxiosInstance.get(`admin/books/${id}`),
  //** pros */
  getProList: (param) =>
    AxiosInstance.get("admin/users/agents", {
      params: fixPageNumber(param),
    }),
  getProDetail: (id) => AxiosInstance.get(`admin/agents/${id}`),
  updateProDetail: (id, data) =>
    AxiosInstance.patch(`admin/agents/${id}`, data),
  getProBidsList: (id) => AxiosInstance.get(`admin/agents/${id}/bids`),
  getProPaymentsList: (id) => AxiosInstance.get(`admin/agents/${id}/payments`),
  approvePro: (data) => AxiosInstance.put(`admin/agents/approve-agent`, data),

  //**settings */
  getSettings: (key) => AxiosInstance.get(`settings/by-key/${key}`),
  updateSettings: (key, value) =>
    AxiosInstance.put(`settings/by-key/${key}`, value),
  createSettings: (data) => AxiosInstance.post("settings", data),

  //**bids */
  getBidList: (param) =>
    AxiosInstance.get("admin/bids", {
      params: fixPageNumber(param),
    }),
  getBidDetail: (id) => AxiosInstance.get(`admin/bids/${id}`),
  getProBidHistory: (id, param) =>
    AxiosInstance.get(`admin/bids/by-agent/${id}`, {
      params: fixPageNumber(param),
    }),
  getBookRequestBidList: (id, param) =>
    AxiosInstance.get(`/admin/bids/by-book/${id}`, {
      params: fixPageNumber(param),
    }),

  //**bids */
  getUnitsOfMeasurement: (param) =>
    AxiosInstance.get("admin/unit-of-measurement", {
      params: fixPageNumber(param),
    }),
  getUnitDetail: (id) => AxiosInstance.get(`admin/unit-of-measurement/${id}`),
  createUnitOfMeasurement: (data) =>
    AxiosInstance.post("admin/unit-of-measurement", data),
  updateUnitsOfMeasurement: (id, data) =>
    AxiosInstance.patch(`admin/unit-of-measurement/${id}`, data),
  getPaymentHistory: (param) =>
    AxiosInstance.get("admin/payment", {
      params: fixPageNumber(param),
    }),
  getPaymentDetail: (id) => AxiosInstance.get(`admin/payment/${id}`),

  //** Permissions */
  getPermissions: () => AxiosInstance.get("permissions/permissions"),
  setPermissions: (data) =>
    AxiosInstance.post("permissions/set-permission", data),

  //** Feedback */
  getFeedbackList: (param) =>
    AxiosInstance.get("admin/feed-backs", {
      params: fixPageNumber(param),
    }),
};

const fixPageNumber = (data) => {
  //***** Pagination component in material UI start from page 0, our backend start from 1,
  // For dealing with API call and handle pagination functions I did this manipulation *****
  let manipulatedPage = data.skip;
  data["skip"] = manipulatedPage;
  return data;
};
