import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Box } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import Results from "./components/Results";
import PageHeader from "./components/PageHeader";
import { useSearchDebounce } from "src/hooks/useSearchDebounce";
import { useSortDebounce } from "src/hooks/useSortDebounce";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";

const Feedback = () => {
  const { handleChangeSearch, query } = useSearchDebounce();
  const { handleChangeSort, order } = useSortDebounce();
  const { toast } = useToast();
  const isSerchedRef = useRef(false);

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const getMyList = useCallback(async () => {
    if (query && isSerchedRef.current && page !== 0) {
      return true;
    }
    let data = {
      take,
      skip: page === 0 ? page : page * take,
    };

    if (query) data.search = query;
    else delete data.search;

    if (order) data.order = JSON.stringify(order);
    else delete data.order;

    try {
      setLoading(true);
      const response = await adminService.getFeedbackList(data);
      isSerchedRef.current = false;
      setLoading(false);
      setRequests(response?.data?.data?.items);
      setTotalCount(response?.data?.data?.count);
    } catch (err) {
      isSerchedRef.current = false;
      setLoading(false);
      toast(errorMessage(err), "error");
    } finally {
      isSerchedRef.current = false;
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, take, query, order]);

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };

  useEffect(() => {
    setPage(0);
    isSerchedRef.current = true;
  }, [query]);

  useEffect(() => {
    getMyList();
  }, [getMyList]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "92vh",
      }}
    >
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: { xs: 1, sm: 1, lg: 4, md: 3, xl: 4 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results
            loading={loading}
            resultData={requests || []}
            pagination={{
              page,
              take,
              totalCount: totalCount,
            }}
            actions={{
              changePage: (page) => handleChangePage(page),
              changeTake: (take) => setTake(take),
              handleSearchBookReq: handleChangeSearch,
              handleSortBookReq: handleChangeSort,
            }}
          />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Feedback;
