import { Box, Drawer, styled, useTheme } from "@mui/material";

import Logo from "../../../LogoSign";
import SidebarMenu from "./SidebarMenu";
import SidebarTopSection from "./SidebarTopSection";
import { useSidebarShow, useSidebarClose } from "src/contexts/GlobalContext";
import SimpleBar from "simplebar-react";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const sidebarShow = useSidebarShow();
  const handleCloseSidebar = useSidebarClose();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <SimpleBar>
          <TopSection>
            <Box
              sx={{
                width: 52,
                mt: 2,
                mb: 3,
              }}
            >
              <Logo />
            </Box>
            {/* <SidebarTopSection /> */}
          </TopSection>
          <SidebarMenu />
        </SimpleBar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarShow}
        onClose={handleCloseSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <SimpleBar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3,
                }}
              >
                <Logo />
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu />
          </SimpleBar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
