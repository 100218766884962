import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  TableSortLabel,
  Button,
  Modal,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { Link } from "react-router-dom";
import { useStyles } from "src/pages/dashboard/styles/main";
import { convertTimezone } from "src/utils/FormatDate";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import truncateText from "src/utils/truncateText";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, username: false });
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const handleOpen = (feedback) => {
    setSelectedFeedback(feedback);
  };

  const handleClose = () => {
    setSelectedFeedback(null);
  };

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  //**** change number of vendors gotten in a page ***
  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };
  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortBookReq({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchBookReq}
          placeholder={"Search by name, mobile, email"}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {loading ? (
              <div className={classes.findingContainer}>
                <CircularProgress className={classes.findingSpinner} />
                trying to find data , Please waite !
              </div>
            ) : (
              "We couldn't find any feedback matching your search criteria"
            )}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id && order !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell style={{ textAlign: "center" }}>
                    {"Phone Number"}
                  </TableCell>
                  <TableCell align="center">{"Email"}</TableCell>
                  <TableCell align="center">{"Subject"}</TableCell>
                  <TableCell align="center">{"Message"}</TableCell>
                  <TableCell align="center">{"Create Date"}</TableCell>
                  <TableCell align="center">{"Actions"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className={loading ? classes.fadedRow : classes.tableRow}
              >
                <div className={classes.spinner}>
                  {loading && <CircularProgress />}
                </div>

                {resultData.map((data, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant="h5">{data?.id || ""}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">{data.fullName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" textAlign="center">
                          {data.mobileNumber || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {data?.email || ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {truncateText(data?.subject || "", 30)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {truncateText(data?.message || "", 30)}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.createdAt
                            ? convertTimezone(data.createdAt)
                            : ""}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          style={{ color: "#fff" }}
                          variant="contained"
                          onClick={() => handleOpen(data)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Modal */}
          <Modal open={Boolean(selectedFeedback)} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                minWidth: 400,
              }}
            >
              {selectedFeedback && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Feedback Details
                  </Typography>
                  <Typography>
                    <strong>Name:</strong> {selectedFeedback.fullName}
                  </Typography>
                  <Typography>
                    <strong>Phone:</strong> {selectedFeedback.mobileNumber}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {selectedFeedback.email}
                  </Typography>
                  <Typography>
                    <strong>Subject:</strong> {selectedFeedback.subject}
                  </Typography>
                  <Typography>
                    <strong>Message:</strong> {selectedFeedback.message}
                  </Typography>
                  <Typography>
                    <strong>Date:</strong>{" "}
                    {selectedFeedback?.createdAt
                      ? convertTimezone(selectedFeedback.createdAt)
                      : ""}{" "}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </>
              )}
            </Box>
          </Modal>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;
