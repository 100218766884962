import React, { useEffect, useRef, useState } from "react";
// import { ImageIcon, PlayCircle } from "lucide-react";

function VideoThumbnail({
  videoUrl,
  width = 320,
  height = 180,
  className = "",
}) {
  const [error, setError] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const generateThumbnail = () => {
      try {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth || width;
        canvas.height = video.videoHeight || height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.8);
          setThumbnail(dataUrl);
        }
      } catch (err) {
        console.error("Error generating thumbnail:", err);
        setError(true);
      }
    };

    const handleLoadedMetadata = () => {
      try {
        video.currentTime = 0.1;
      } catch (err) {
        console.error("Error seeking video:", err);
        setError(true);
      }
    };

    const handleSeeked = () => {
      generateThumbnail();
    };

    const handleError = (e) => {
      console.error("Error loading video:", e);
      setError(true);
    };

    video.crossOrigin = "anonymous";
    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("seeked", handleSeeked);
    video.addEventListener("error", handleError);
    video.src = videoUrl;
    video.load();

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("seeked", handleSeeked);
      video.removeEventListener("error", handleError);
      video.src = "";
    };
  }, [videoUrl, width, height]);

  if (error) {
    return (
      <div
        style={{ width, height }}
        className="flex flex-col items-center justify-center bg-gray-100 rounded-lg"
      >
        <span className="text-sm text-gray-500">Unable to load thumbnail</span>
      </div>
    );
  }

  return (
    <div className="relative" style={{ width, height }}>
      <video ref={videoRef} preload="metadata" style={{ display: "none" }} />
      {thumbnail ? (
        <div className="relative group">
          <img
            src={thumbnail}
            alt="Video thumbnail"
            style={{ width, height }}
            className="rounded-lg shadow-md object-cover"
          />
          <a
            href={videoUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all rounded-lg ${className}`}
          >
            {/* <PlayCircle className="w-12 h-12 text-white opacity-0 group-hover:opacity-100 transition-opacity" /> */}
          </a>
        </div>
      ) : (
        <div
          style={{ width, height }}
          className="flex items-center justify-center bg-gray-100 rounded-lg animate-pulse"
        >
          <span className="text-gray-400">Loading...</span>
        </div>
      )}
    </div>
  );
}

export default VideoThumbnail;
